<script>
import * as echarts from "echarts";
import taiWan from "../../../public/map/台湾省.json";
import { getMap } from "@/api/data.js";
import { getSurnameList, searchName } from "@/api/data";
export default {
  data() {
    return {
      areaShow: false,
      thermodynamicsList: [],
      timer: null,
      chart: null,
      surnameEchDom: null,
      surnameEchChart: null,
      areaEchDom: null,
      areaEchChart: null,
      surnameEchDomShow: false,
      mapNameList: ["台湾省"],
      mapIndex: 0,
      click_type: false,
      Surname: "",
      nameAreaList: [],
      placeName: "",
      seriesData: [], //决定图表中显示的个数
      selected: {}, // 决定图表中显示的个数
      areaList: [],
    };
  },
  created() {
    this.areaList = [...new Set(taiWan.features.map((v) => v.properties.name))];
  },
  mounted() {
    this.init();
    this.surnameEchDom = this.$refs.surnameEch;
    this.areaEchDom = this.$refs.areaEch;
    this.chart.on("dblclick", this.mapDblclick);
    this.chart.on("click", this.mapClick);
  },
  methods: {
    async init() {
      const echartMapDom = this.$refs.echart;
      if (echartMapDom) {
        this.chart = echarts.init(echartMapDom);
        this.chart.showLoading();
        echarts.registerMap("台湾省", taiWan);
        await this.getSurnameList();
        const option = this.loadMap("台湾省", this.thermodynamicsList);
        this.chart.setOption(option, true);
        this.chart.hideLoading();
      }
    },
    loadMap(name, dataList) {
      return {
        title: {
          left: "center",
          textStyle: {
            color: "#fff",
            fontSize: 30,
          },
          subtextStyle: {
            fontSize: 20,
          },
        },
        tooltip: {
          tagger: "item",
          formatter: function (params) {
            let str = "";
            if (params.data) {
              let arr = [];
              arr =
                params.data.surnameList.length <= 10
                  ? params.data.surnameList
                  : params.data.surnameList.slice(0, 10);
              arr.forEach((v) => {
                str += `<span>${v.surname}:${v.count}</span></br>`;
              });
            } else {
              str = `<span>暂无数据</span></br>`;
            }
            return str;
          },
        },

        series: [
          {
            data: dataList.map((item) => ({
              name: item.endPlace.split("/")[
                item.endPlace.split("/").length - 1
              ],
              surnameList: item.surnameModels,
            })),
            type: "map",
            map: name,
            zoom: 1.2,
            roam: true,
            aspectScale: 0.75,
            // 默认文本标签样式
            label: {
              offset: [0, 0], // 偏移设置
              color: "#fff",
              show: true,
            },
            itemStyle: {
              areaColor: "rgba(34, 70, 168, 0.7)",
              borderColor: "#0692a4",
            },
            emphasis: {
              // 高亮文本标签样式
              label: {
                color: "#fff",
                fontSize: 22,
                fontWeight: "bold",
              },
              // 高亮区域背景色
              itemStyle: {
                areaColor: "rgba(119, 139, 224, 0.548)",
              },
            },
            // itemStyle: {
            //   areaColor: "#FFF5E1",
            //   borderColor: "rgba(39,211,233, 1)",
            //   borderWidth: 1,
            // },
            // emphasis: {
            //   // 高亮文本标签样式
            //   label: {
            //     color: "#000",
            //     fontSize: 22,
            //     fontWeight: "bold",
            //   },
            //   // 高亮区域背景色
            //   itemStyle: {
            //     areaColor: "#01ADF2",
            //   },
            // },
            // 点击选择地图高亮颜色
            select: {
              itemStyle: {
                color: "#01adf2",
              },
            },
          },
        ],
      };
    },
    mapClick(params, status) {
      this.click_type = false;
      setTimeout(async () => {
        if (this.click_type != false) return;
        this.surnameEchDomShow = false;
        this.areaShow = false;
        this.mapIndex = this.mapNameList.length - 1;
        if (this.mapNameList.length === 4) {
          this.mapNameList.pop();
        }
        if (this.mapIndex >= 2) return;
        if (params.name.includes("(海)")) return;
        if (status !== "goBack") {
          this.mapNameList.push(params.name);
        }
        try {
          this.chart.showLoading();
          //去掉村里名包含阿拉伯数字的--开始
          let geoData = await getMap(
            `${window.location.origin}/map/${this.mapNameList.join("")}.json`
          );
          let temFeatures = [];
          var regex = /[0-9]/;
          geoData.features.forEach((tItem) => {
            if (!regex.test(tItem.properties.V_Name)) {
              temFeatures.push(tItem);
            }
          });
          geoData.features = temFeatures;
          //去掉村里名包含阿拉伯数字的--结束
          this.areaList = [
            ...new Set(geoData.features.map((v) => v.properties.name)),
          ];
          echarts.registerMap(params.name, geoData);
          await this.getSurnameList();
          if (this.Surname) {
            this.onSearch();
          }
          let option = this.loadMap(params.name, this.thermodynamicsList);
          this.chart.setOption(option, true);
          this.chart.hideLoading();
          window.addEventListener("resize", function () {
            this.chart.resize();
          });
        } catch (error) {
          console.log(error);
          this.chart.hideLoading();
        }
      }, 400);
    },
    async getSurnameList() {
      const res = await getSurnameList({
        endPlace: this.mapNameList.join("/"),
      });
      this.thermodynamicsList = res;
    },
    surnameEchInit(data) {
      if (this.surnameEchChart) {
        this.surnameEchChart.dispose();
      }
      this.surnameEchChart = echarts.init(this.surnameEchDom);

      let option = {
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c}({d}%)",
        },
        legend: {
          orient: "vertical",
          left: "right",
          right: 0,
          top: 0,
          bottom: 20,
          type: "scroll", // 数据过多时，分页显示
          selected: this.selected, //这里默认显示数组中前十个，如果不设置，则所有的数据都会显示在图表上
          textStyle: {
            color: "#fff", // 设置字体颜色，这里是红色
            fontSize: 14, // 设置字体大小，可根据需要调整
          },
        },

        series: [
          {
            type: "pie",
            radius: "50%",
            label: {
              normal: {
                show: true,
                color: "#fff",
                // formatter: "{b}: {c}({d}%)", //自定义显示格式(b:name, c:value, d:百分比)
              },
            },
            data: data.map((item) => ({
              name: item.surname,
              value: item.count,
            })),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0,0,0,0.5)",
              },
            },
          },
        ],
      };
      this.surnameEchChart.setOption(option, true);
    },
    areaEchInit(data) {
      if (this.areaEchChart) {
        this.areaEchChart.dispose();
      }
      this.areaEchChart = echarts.init(this.areaEchDom);
      let option = {
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c}({d}%)",
        },

        series: [
          {
            type: "pie",
            radius: "50%",
            label: {
              normal: {
                show: true,
                color: "#fff",
                // formatter: "{b}: {c}({d}%)", //自定义显示格式(b:name, c:value, d:百分比)
              },
            },
            data: data.map((item) => ({
              name: item.endPlace.split("/")[
                item.endPlace.split("/").length - 1
              ],
              value: item.count,
            })),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0,0,0,0.5)",
              },
            },
          },
        ],
      };
      this.areaEchChart.setOption(option, true);
    },
    async mapDblclick(params) {
      this.click_type = true;
      this.surnameEchDomShow = true;
      this.placeName = params.name;

      for (let i = 0; i < params.data.surnameList.length; i++) {
        let name = params.data.surnameList[i].surname;
        this.seriesData.push({
          surname: params.data.surnameList[i].surname,
          count: params.data.surnameList[i].count,
        });
        this.selected[name] = i < 20;
      }

      await this.surnameEchInit(params.data.surnameList);
    },
    goBack() {
      if (this.mapNameList.length === 1) {
        return this.$message.info("已是第一级");
      }
      this.mapNameList.pop();
      this.mapClick(
        { name: this.mapNameList[this.mapNameList.length - 1] },
        "goBack"
      );
    },
    onClick(name) {
      this.mapClick({ name });
    },
    async onSearch() {
      const res = await searchName({
        Surname: this.Surname,
        endPlace: this.mapNameList.join("/"),
      });
      this.nameAreaList = res;
      if (res.length) {
        this.areaShow = true;
        this.areaEchInit(this.nameAreaList);
      } else {
        this.$message.info("暂无数据");
      }
    },
  },
};
</script>

<template>
  <div class="surname-container">
    <div class="map">
      <div ref="echart" class="echartMap"></div>
    </div>

    <div class="pie-chart" v-show="surnameEchDomShow">
      <p>{{ placeName }}各姓氏统计图</p>
      <div class="surnameEch" ref="surnameEch"></div>
      <div class="footer"></div>
    </div>

    <div class="pie-chart2" v-show="areaShow">
      <p>{{ Surname }}姓各地区统计图</p>
      <div class="areaEch" ref="areaEch"></div>
      <div class="footer"></div>
    </div>
    <div class="btn">
      <el-button @click="goBack" type="primary" plain>上一级</el-button>
      <el-input v-model="Surname" placeholder="请输入姓氏"></el-input>
      <el-button @click="onSearch" type="primary" plain>搜索</el-button>
    </div>
    <ul class="area">
      <p class="header"></p>
      <li v-for="(v, i) in areaList" :key="i" @click="onClick(v)">
        <van-icon
          class="iconfont"
          class-prefix="icon"
          name="diqufenbu"
          style="font-size: 14px"
        ></van-icon>
        <span style="margin-left: 5px">{{ v }}</span>
      </li>
      <p class="footer"></p>
    </ul>
  </div>
</template>

<style scoped lang="scss">
::v-deep .el-button {
  background: #1c379a;
  border-color: #174191;
}
.surname-container {
  position: relative;
  // background-image: url("../../assets/404_images/bg.jpg");
  background: #03044a;
  .map {
    .echartMap {
      width: 100%;
      height: 100vh;
      // height: calc(100vh - 50px);
    }
  }
  .pie-chart {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 500px;
    height: 460px;
    background: #03044a;
    border: 1px solid rgba(25, 186, 139, 0.17);
    background-image: url("../../assets/404_images/line.png");

    .surnameEch {
      width: 500px;
      height: 440px;
    }
    .footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
    .footer::before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 10px;
      height: 10px;
      border-left: 2px solid #02a6b5;
      border-bottom: 2px solid #02a6b5;
      content: "";
    }
    .footer::after {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 10px;
      height: 10px;
      border-right: 2px solid #02a6b5;
      border-bottom: 2px solid #02a6b5;
      content: "";
    }
    p {
      height: 20px;
      text-align: center;
      line-height: 30px;
      font-size: 20px;
      margin: 0;
      color: #fff;
      font-weight: 600;
    }
    p::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 10px;
      height: 10px;
      border-left: 2px solid #02a6b5;
      border-top: 2px solid #02a6b5;
      content: "";
    }
    p::after {
      position: absolute;
      top: 0;
      right: 0;
      width: 10px;
      height: 10px;
      border-right: 2px solid #02a6b5;
      border-top: 2px solid #02a6b5;
      content: "";
    }
  }
  .pie-chart2 {
    position: absolute;
    top: 470px;
    right: 2px;
    width: 500px;
    height: calc(100% - 470px);
    background: #03044a;
    border: 1px solid rgba(25, 186, 139, 0.17);
    background-image: url("../../assets/404_images/line.png");

    .areaEch {
      width: 500px;
      height: 360px;
    }
    p {
      height: 20px;
      line-height: 30px;
      font-size: 20px;
      text-align: center;
      margin: 0;
      color: #fff;
      font-weight: 600;
    }
    .footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
    .footer::before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 10px;
      height: 10px;
      border-left: 2px solid #02a6b5;
      border-bottom: 2px solid #02a6b5;
      content: "";
    }
    .footer::after {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 10px;
      height: 10px;
      border-right: 2px solid #02a6b5;
      border-bottom: 2px solid #02a6b5;
      content: "";
    }
    p::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 10px;
      height: 10px;
      border-left: 2px solid #02a6b5;
      border-top: 2px solid #02a6b5;
      content: "";
    }
    p::after {
      position: absolute;
      top: 0;
      right: 0;
      width: 10px;
      height: 10px;
      border-right: 2px solid #02a6b5;
      border-top: 2px solid #02a6b5;
      content: "";
    }
  }

  .btn {
    position: absolute;
    width: 400px;
    justify-content: space-around;
    left: 10px;
    top: 10px;
    display: flex;
    .el-input {
      margin: 0 5px;
    }
  }
  .area {
    position: absolute;
    left: 10px;
    top: 60px;
    display: flex;
    width: 400px;
    height: calc(100vh - 100px);
    border: 1px solid rgba(25, 186, 139, 0.17);
    padding: 30px 50px;
    background: #03044a;
    flex-wrap: wrap;
    background-image: url("../../assets/404_images/line.png");
    align-content: flex-start;
    overflow: auto;
    box-sizing: border-box;
    li {
      color: #fff;
      width: 140px;
      line-height: 50px;
      height: 50px;
      text-align: center;
      cursor: pointer;

      span {
        font-size: 18px;
      }
    }
    li:hover {
      color: #02a6b5;
    }

    .footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
    .footer::before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 10px;
      height: 10px;
      border-left: 2px solid #02a6b5;
      border-bottom: 2px solid #02a6b5;
      content: "";
    }
    .footer::after {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 10px;
      height: 10px;
      border-right: 2px solid #02a6b5;
      border-bottom: 2px solid #02a6b5;
      content: "";
    }
    .header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
    .header::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 10px;
      height: 10px;
      border-left: 2px solid #02a6b5;
      border-top: 2px solid #02a6b5;
      content: "";
    }
    .header::after {
      position: absolute;
      top: 0;
      right: 0;
      width: 10px;
      height: 10px;
      border-right: 2px solid #02a6b5;
      border-top: 2px solid #02a6b5;
      content: "";
    }
  }
}
</style>

